import { MeatCut } from '.'

export const tiles: MeatCut[] = [
  {
    position: {
      left: 246,
      top: 125,
      width: 113,
      height: 96,
    },
    image: undefined,
    path: 'product-detailed/sheep/5065.png',
    code: '5065',
    name: 'Leg cuts',
    description: '',
    hidden: true,
  },
  {
    position: {
      left: 112,
      top: 266,
      width: 120,
      height: 86,
    },
    image: undefined,
    path: 'product-detailed/sheep/4880.png',
    code: '4880',
    name: 'Short loin',
    description: '',
  },
  {
    position: {
      left: 234,
      top: 262,
      width: 115,
      height: 100,
    },
    image: undefined,
    path: 'product-detailed/sheep/4860.png',
    code: '4860',
    name: 'Loin',
    description: '',
  },
  {
    position: {
      left: 130,
      top: 122,
      width: 61,
      height: 72,
    },
    image: undefined,
    path: 'product-detailed/sheep/5071.png',
    code: '5071',
    name: 'Silverside',
    description: '',
    hidden: true,
  },
  {
    position: {
      left: 185,
      top: 131,
      width: 51,
      height: 58,
    },
    image: undefined,
    path: 'product-detailed/sheep/5074.png',
    code: '5074',
    name: 'Rump',
    description: '',
    hidden: true,
  },
  {
    position: {
      left: 179,
      top: 183,
      width: 56,
      height: 69,
    },
    image: undefined,
    path: 'product-detailed/sheep/5073.png',
    code: '5073',
    name: 'Topside',
    description: '',
    hidden: true,
  },
  {
    position: {
      left: 122,
      top: 187,
      width: 56,
      height: 66,
    },
    image: undefined,
    path: 'product-detailed/sheep/5072.png',
    code: '5072',
    name: 'Knuckle',
    description: '',
    hidden: true,
  },
  {
    position: {
      left: 247,
      top: 363,
      width: 87,
      height: 85,
    },
    image: undefined,
    path: 'product-detailed/sheep/4932.png',
    code: '4932',
    name: 'Rack',
    description: '',
  },
  {
    position: {
      left: 153,
      top: 357,
      width: 89,
      height: 87,
    },
    image: undefined,
    path: 'product-detailed/sheep/4938.png',
    code: '4938',
    name: 'Rack (Frenched)',
    description: '',
    hidden: true,
  },
  {
    position: {
      left: 74,
      top: 373,
      width: 89,
      height: 110,
    },
    image: undefined,
    path: 'product-detailed/sheep/4938A.png',
    code: '4938A',
    name: 'Rack (Frenched) cap off',
    description: '',
    hidden: true,
  },
  {
    position: {
      left: 118,
      top: 533,
      width: 124,
      height: 90,
    },
    image: undefined,
    path: 'product-detailed/sheep/5050.png',
    code: '5050',
    name: 'Shoulder - Square cut rolled/netted',
    description: '',
  },
  {
    position: {
      left: 181,
      top: 464,
      width: 130,
      height: 101,
    },
    image: undefined,
    path: 'product-detailed/sheep/4990.png',
    code: '4990',
    name: 'Square cut shoulder',
    description: '',
    hidden: true,
  },
  {
    position: {
      left: 358,
      top: 563,
      width: 90,
      height: 57,
    },
    image: undefined,
    path: 'product-detailed/sheep/5020.png',
    code: '5020',
    name: 'Neck',
    description: '',
  },
  {
    position: {
      left: 586,
      top: 560,
      width: 87,
      height: 58,
    },
    image: undefined,
    path: 'product-detailed/sheep/5030.png',
    code: '5030',
    name: 'Fore shank',
    description: '',
  },
  {
    position: {
      left: 684,
      top: 504,
      width: 144,
      height: 117,
    },
    image: undefined,
    path: 'product-detailed/sheep/5010.png',
    code: '5010',
    name: 'Breast and flap',
    description: '',
  },
  {
    position: {
      left: 652,
      top: 443,
      width: 176,
      height: 92,
    },
    image: undefined,
    path: 'product-detailed/sheep/5109.png',
    code: '5109',
    name: 'Backstrap',
    description: '',
  },
  {
    position: {
      left: 627,
      top: 347,
      width: 116,
      height: 93,
    },
    image: undefined,
    path: 'product-detailed/sheep/5080.png',
    code: '5080',
    name: 'Tenderloin',
    description: '',
  },
  {
    position: {
      left: 766,
      top: 346,
      width: 94,
      height: 94,
    },
    image: undefined,
    path: 'product-detailed/sheep/4830.png',
    code: '4830',
    name: 'Leg - Chump off shank off',
    description: '',
  },
  {
    position: {
      left: 766,
      top: 231,
      width: 94,
      height: 94,
    },
    image: undefined,
    path: 'product-detailed/sheep/4810.png',
    code: '4810',
    name: 'Leg - Chump on - Shank off',
    description: '',
  },
  {
    position: {
      left: 598,
      top: 283,
      width: 163,
      height: 70,
    },
    image: undefined,
    path: 'product-detailed/sheep/5070.png',
    code: '5070',
    name: 'Leg - Chump off - Shank off',
    description: '',
  },
  {
    position: {
      left: 633,
      top: 183,
      width: 127,
      height: 96,
    },
    image: undefined,
    path: 'product-detailed/sheep/5060.png',
    code: '5060',
    name: 'Leg - Boneless',
    description: '',
  },
  {
    position: {
      left: 578,
      top: 114,
      width: 128,
      height: 99,
    },
    image: undefined,
    path: 'product-detailed/sheep/4800.png',
    code: '4800',
    name: 'Leg - Chump on',
    description: '',
  },
  {
    position: {
      left: 756,
      top: 101,
      width: 106,
      height: 112,
    },
    image: undefined,
    path: 'product-detailed/sheep/4820.png',
    code: '4820',
    name: 'Leg - Chunk off - Shank on',
    description: '',
  },
]
